<template>
  <v-row align="center" justify="center" class="fill-height" :style="{ zIndex: '11' }" no-gutters>
    <!-- <v-col class="dark fill-height" cols="6">
      <Carousel />
    </v-col> -->
    <v-col>
      <v-card max-width="600" width="100%" class="py-5 box-shadow mx-auto" outlined>
        <v-sheet :max-width="$dialog.content" class="mx-auto">
          <div>
            <v-img src="@/assets/viva_city_logo.png" width="190" class="mr-auto ml-auto mt-4"/>
          </div>

          <signup-form signupOnly/>
        </v-sheet>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import firebase from 'firebase'

import SignupForm from '@/components/SignupForm'
// import Carousel from '../orders/components/OrderCarousel.vue'

export default {
  name: 'Signup',

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    // Carousel,
    SignupForm,
  },

  /*------------------------------------------------------------------------------
   * mounted
   *----------------------------------------------------------------------------*/
  mounted() {
    firebase.auth()
      .onAuthStateChanged(() => {
        if (firebase.auth().currentUser) {
          this.$router.push({ name: 'Requests' })
        }
      })
  }
}
</script>